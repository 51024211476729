@font-face {
  font-family: "ALS Hauss";
  src: url("../fonts/ALSHauss-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ALS Hauss";
  src: url("../fonts/ALSHauss-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "ALS Hauss";
  src: url("../fonts/ALSHauss-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
